
.gallery-content-row{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    justify-content: center;
    overflow: hidden;
}
.gallery-photo{
    width: 100%;
}
.gallery-flex-section{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.gallery-colum-1, .gallery-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
.gallery-colum-2{
    text-align: center;
}
@media only screen and (min-width: 813px){
    .gallery-content-row{
        margin: 50px 0px;
    }
    .gallery-colum-1{
        width: 100%;
    }
    .gallery-colum-2{
        width: 50%; 
    }
    .gallery-photo{
        max-width: 500px;
        margin: auto;
    }
    .gallery-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 18px;
    }
}

