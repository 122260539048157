.home-content{
    font-size: 18px;
    width: 95%;
    margin: 20px auto;
}
.home-content h1{text-align: center;}

@media only screen and (max-width: 400px){
    
}
@media only screen and (min-width: 889px){
    .home-content p{
        font-size: 18px;
    }
}
