/*https://codepen.io/npellow/pen/yzZQOw*/

.Photos {
    line-height: 0;
    column-count: 5;
    column-gap: 0px;  
}
  
.Photos img {
    width: 100% !important;
    height: auto !important;
}

@media (max-width: 1200px) {
    .Photos {
        column-count: 4;
    }
}
@media (max-width: 1000px) {
    .Photos {
        column-count:  3;
    }
}
@media (max-width: 800px) {
    .Photos {
        column-count: 2;
    }
}
@media (max-width: 400px) {
    .Photos {
        column-count: 1;
    }
}

  
