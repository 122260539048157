.content{
    font-size: 18px;
}
.contact-content-row{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    justify-content: center;
    overflow: hidden;
}
.contact-photo{
    width: 100%;
}
.site-colum-1, .site-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
@media only screen and (min-width: 813px){
    .contact-content-row{
        margin: 50px 0px;
    }
    .site-colum-1{
        width: 100%;
    }
    .site-colum-2{
        width: 50%; 
    }
    .contact-photo{
        max-width: 505px;
        max-height: 309px;
        margin: auto;
    }
    .site-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 18px;
    }
}

