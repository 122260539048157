/*--------------------------------------------------------------
CSS Reset
--------------------------------------------------------------*/
html {
	font-family: sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust:     100%;
}

body {
	margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

a {
	background-color: transparent;
}

a:active,
a:hover {
	outline: 0;
}

abbr[title] {
	border-bottom: 1px dotted;
}

b,
strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

mark {
	background: #ff0;
	color: #000;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

img {
	display: block;
	border: 0;
	width: 100%;
	height: auto;
}

svg:not(:root) {
	overflow: hidden;
}

figure {
	margin: 0;
}

hr {
	box-sizing: content-box;
	height: 0;
}

pre {
	overflow: auto;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input {
	line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	
}

textarea {
	overflow: auto;
}

optgroup {
	font-weight: bold;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}
/*****************************************************
 *                  CLICKER SCRIPT FONTS             *
 ****************************************************/
@font-face {
    font-family: ClickerScript-Regular;
    src: url(/static/media/ClickerScript-Regular.b5cae760.ttf);
}

/*****************************************************
 *                  COOPER HEWITT FONTS              *
 ****************************************************/
@font-face {
    font-family: CooperHewitt-Bold;
    src: url(/static/media/CooperHewitt-Bold.27aceeb7.otf);
}
@font-face {
    font-family: CooperHewitt-BoldItalic;
    src: url(/static/media/CooperHewitt-BoldItalic.add4deb1.otf);
}
@font-face {
    font-family: CooperHewitt-Book;
    src: url(/static/media/CooperHewitt-Book.375f05aa.otf);
}
@font-face {
    font-family: CooperHewitt-BookItalic;
    src: url(/static/media/CooperHewitt-BookItalic.679e36d6.otf);
}
@font-face {
    font-family: CooperHewitt-Heavy;
    src: url(/static/media/CooperHewitt-Heavy.220a3729.otf);
}
@font-face {
    font-family: CooperHewitt-HeavyItalic;
    src: url(/static/media/CooperHewitt-HeavyItalic.59df046a.otf);
}
@font-face {
    font-family: CooperHewitt-Light;
    src: url(/static/media/CooperHewitt-Light.c673f1bd.otf);
}
@font-face {
    font-family: CooperHewitt-LightItalic;
    src: url(/static/media/CooperHewitt-LightItalic.387e144f.otf);
}
@font-face {
    font-family: CooperHewitt-Medium;
    src: url(/static/media/CooperHewitt-Medium.da25a0c1.otf);
}
@font-face {
    font-family: CooperHewitt-MediumItalic;
    src: url(/static/media/CooperHewitt-MediumItalic.a26006d1.otf);
}
@font-face {
    font-family: CooperHewitt-Semibold;
    src: url(/static/media/CooperHewitt-Semibold.b5da7aed.otf);
}
@font-face {
    font-family: CooperHewitt-SemiboldItalic;
    src: url(/static/media/CooperHewitt-SemiboldItalic.1072b9dc.otf);
}
@font-face {
    font-family: CooperHewitt-Thin;
    src: url(/static/media/CooperHewitt-Thin.3d6bcfcb.otf);
}
@font-face {
    font-family: CooperHewitt-ThinItalic;
    src: url(/static/media/CooperHewitt-ThinItalic.efb3c88c.otf);
}
body{
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-size: 18px;
  font-family: 'CooperHewitt-Book';
  margin: auto;
  
}

.site-component-wrapper{
  opacity: 0;
  transition: opacity 0.4s;
}


h1, h2, h3, h4, h5{
  font-family: 'ClickerScript-Regular';
  color: #cc9933;
}
h1{font-size: 3.55em}
h2{font-size: 2.66em}
h3{font-size: 1.66em}
h4{font-size: 1.3em}

p{
  font-family: 'CooperHewitt-Book';
  color: #003333;
}

main h1{
  text-align: center;
}
main .content{
  width: 95%;
  margin: 65px auto;
}

.content a, .content a:visited, .content a:focus{
  color:#7a9d96;
}
.rotate-20{
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

main{
  transition: opacity 0.4s;
}

@media only screen and (min-width: 889px){
  .content p{
    font-size: 18px;
  }
}



.loader-container{
    background: #ffffff99;
    color: #6e9e96;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    top:0
}
.loader-wrapper{
    background: white;
    width: 150px;
    height: 150px;
    text-align: center;
    border: 1px solid #6e9e96;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.ajax-loader{
    width: 32px;
}
.loader-image{
    margin: 5px 0;
}
.loader-caption{
    margin: 5px 0;
}
.site-header{
    background: white;
    font-family: 'Clicker Script';
}
.mobile-nav-bar{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: 1px solid #7a9d96;
}
.mobile-logo{
    max-width: 50px;
}

.bars-wrapper{
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #7a9d96;
    padding: 0 5px 0px 5px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #7a9d96;
    margin: 6px 0;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    z-index: -1;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}
.logo-image{
    display: inline-block;
}
.main-menu a, .main-menu a:visited, .main-menu a:focus{
    color: #cc9933;
    cursor:pointer;
}
.arrow{
    font-family: FontAwesome;
    float: right;

}
.arrow-down::after{
    content: '\F107';
}
.main-menu ul{
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.main-menu ul li{
    list-style: none;
    position: relative;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    text-align: left;
}
.main-menu a{
    text-decoration: none;
    display: block;
    font-size: 2em;
    padding: .8rem 1rem;
    font-family: ClickerScript-Regular;
}

.main-menu ul li ul li{
    width: 100%;
}

#accordion{
    cursor:pointer;
}

@media only screen and (max-width: 888px){
    .mobile-nav-bar{
        position: fixed;
        width: 100%;
        z-index: 9;
        top:0;
        background: white;
    }
    .logo-wrapper{
        display: none;
    }
    .main-menu{
        position: fixed;
        width: 100%;
        background: white;
        height: calc(100vh - 48px);
        top: 48px;
        overflow-y: scroll;
        left: -100%;
        transition: left 0.4s ease-out;
        z-index: 10;
    }
    .main-menu a{
        font-size: 1.2em;
    }
    .main-menu ul li a{
        border-bottom: 1px solid #7a9d96;
    }
    .menu-panel{
        background-color: #cae4db;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    main{
        margin-top: 58px;
        
    }
}

@media only screen and (min-width: 889px){
    .site-header{
        border-bottom: 20px solid #7a9d96;
    }
    .mobile-nav-bar{
        display: none;
    }
    .main-menu ul{
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .main-menu ul li{
        -webkit-align-self: center;
                align-self: center;
    }
    .main-menu ul li a {
        text-align: center;
    }
	
	/*
    .main-menu ul li a:hover{
        border-bottom: 1px solid #cc9933;
    }
	*/
	
	.main-menu ul li a::after {
        content: '';
		display: block;
		height: 2px;
		background: #cc9933;
		width: 0;
		transition: width 0.6s;
    }
	.main-menu ul li a:hover::after{
		height: 2px;
		width: 100%;
		transition: width 0.6s;
    }
	
    .main-menu ul li.logo-wrapper a:hover{
        border-bottom: none;
    }
	.main-menu ul li.logo-wrapper a:hover::after{
        width: 0;
    }
    .main-menu ul li ul{
        display: none;
        position: absolute;
        width: 200%;
        z-index: 1;
        background: #f3f3f3;
    }
    .main-menu ul li:hover ul{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .main-menu ul li ul li a{
        padding-left: 2rem;
    }

    .logo-wrapper{
        text-align: center;
        margin: 15px auto;
    }

    .logo-image{
        max-width: 250px;
    }
}



.home-content{
    font-size: 18px;
    width: 95%;
    margin: 20px auto;
}
.home-content h1{text-align: center;}

@media only screen and (max-width: 400px){
    
}
@media only screen and (min-width: 889px){
    .home-content p{
        font-size: 18px;
    }
}

.carousel-container {
    position: relative;
	width: 100%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
}
.carousel-slider-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
	transition: all 0.5s;
	
}
.carousel-slide {
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
    height: 100%;
    width: 100%;;
	text-align: center;
}

.carousel-slide img{
    max-width: 1200px;
    margin: 5px auto;
}

.carousel-arrow {
    height: 50px;
    width: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: #ecf1f1;
    border-radius: 50%;
    cursor: pointer;
    transition: -webkit-transform ease-in .1s;
    transition: transform ease-in .1s;
    transition: transform ease-in .1s, -webkit-transform ease-in .1s;
}
.carousel-previous-arrow {
    position: absolute;
    top: 50%;
    left: 25px;
    z-index: 5;
	color: #7a9d96;
}

.carousel-next-arrow {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 5;
	color: #7a9d96;
}

.fa-arrow-right:before, .fa-arrow-left:before {
    color: #7a9d96;
}
.instagram-section{
    width: 100%;
    text-align: center;
    min-height: 513px;
    background-color: #7a9d96;
    color:#ffffff;
}
.instagram-title{
    font-family: 'ClickerScript-Regular';
    font-size: 2.6em;
}
.instagram-username{
    font-family: 'CooperHewitt-Book';
    font-size: 1.4em;
}
.instagram-gallery{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}
.instagram-photo-wrapper{
    margin: 2px;
}
.instagram-photo{
    width: 150px;
    height: 150px;
}
@media only screen and (max-width: 400px){
    
}
@media only screen and (min-width: 889px){
    .instagram-title{
        font-size: 3.55em;
    }
    .instagram-username{
        font-size: 1.77em;
    }
}

.gallery-content-row{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 10px 0px;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}
.gallery-photo{
    width: 100%;
}
.gallery-flex-section{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.gallery-colum-1, .gallery-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
.gallery-colum-2{
    text-align: center;
}
@media only screen and (min-width: 813px){
    .gallery-content-row{
        margin: 50px 0px;
    }
    .gallery-colum-1{
        width: 100%;
    }
    .gallery-colum-2{
        width: 50%; 
    }
    .gallery-photo{
        max-width: 500px;
        margin: auto;
    }
    .gallery-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 18px;
    }
}


.portfolio-gallery{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    width: 95%;
    margin: 65px auto;
}
.photo-gallery{
    width: 300px;
    margin: 25px;
    padding: 10px 10px 20px 10px;
    border: 1px solid #aaaaaa;
	cursor: pointer;
}
.photo-gallery .caption{
    text-align: center;
    font-family: 'ClickerScript-Regular';
    color: #cc9933;
    font-size: 18px;
}
.photo-gallery img{
    max-width: 300px;
    max-height: 200px;
}

@media only screen and (min-width: 750px){
    .portfolio-gallery .photo-gallery:nth-child(odd){ /* IE 9 */
        -webkit-transform: rotate(7deg); /* Safari */
        transform: rotate(7deg);
		transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		transition: transform 0.6s, -webkit-transform 0.6s;
    }
	
    .portfolio-gallery .photo-gallery:nth-child(even){ /* IE 9 */
        -webkit-transform: rotate(-8deg); /* Safari */
        transform: rotate(-8deg);
		transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		transition: transform 0.6s, -webkit-transform 0.6s;
    }
	
	.portfolio-gallery .photo-gallery:hover{
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

    .photo-gallery{
        box-shadow: 5px 5px 5px #aaaaaa;
        border:none;
    }
}

.site-light-box{
    background: black;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    display: -webkit-flex;
    display: flex;
    opacity: 0;
    transition: opacity 0.4s;
}
.show-lightbox{
    opacity: 1;
    z-index: 20;
}
.site-light-box-container{
    background: white;
    width: 80vw;
    margin: auto;
    position: relative;
    max-height: 80vh;
    min-height: 150px;
}
.site-light-box-wrapper{
    max-height: 80vh;
    overflow-y: scroll;
}

.site-light-box-close{
    position: absolute;
    top: -36px;
    right: 0;
    color: #ffffff;
    font-size: 2em;
    cursor: pointer;
}
.site-light-box-prev{
    position: absolute;
    color: #ffffff;
    font-size: 2em;
    left: -30px;
    top: 50%;
    cursor: pointer;
}
.site-light-box-next{
    position: absolute;
    color: #ffffff;
    font-size: 2em;
    right: -30px;
    top: 50%;
    cursor: pointer;
}
.site-light-box-image{
    opacity: 1;
    transition: opacity 0.4s;
}

main .content {
    width: 100%;
}
.content{
    font-size: 18px;
}
.price-content .content{
    border-bottom: 20px solid #6e9e96;
}

.price-content-row{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 10px 0px;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}
.price-photo{
    width: 100%;
}
.price-caption{
    text-align: center;
    color: #cc9933;
    font-family: ClickerScript-Regular;
}
.site-colum-1, .site-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
.site-colum-1 figure, .site-colum-2 figure{
    width: 95%;
    margin: auto;
    text-align: center;
}
.price-page-paragrpah{
    width: 95%;
    margin: auto;
    text-align: center;
}

@media only screen and (min-width: 813px){

    .price-content-row{
        margin: 50px 0px;
    }
    .site-colum-1{
        width: 100%;
    }
    .site-colum-2{
        width: 50%; 
    }
    .price-photo{
        max-width: 505px;
        max-height: 309px;
        margin: auto;
    }
    .price-page-paragrpah{
        font-size: 18px;
    }
    .site-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 28px !important;
        color: #cc9933;
        font-family: ClickerScript-Regular;
    }
    
}


.content{
    font-size: 18px;
}
.contact-content-row{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 10px 0px;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}
.contact-photo{
    width: 100%;
}
.site-colum-1, .site-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
@media only screen and (min-width: 813px){
    .contact-content-row{
        margin: 50px 0px;
    }
    .site-colum-1{
        width: 100%;
    }
    .site-colum-2{
        width: 50%; 
    }
    .contact-photo{
        max-width: 505px;
        max-height: 309px;
        margin: auto;
    }
    .site-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 18px;
    }
}


.contact-form-section input, .contact-form-section textarea{
    line-height: normal;
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: ClickerScript-Regular;
    padding: 5px;
    border: 1px solid #cc9933;
    color: #cc9933;
}

.contact-form-section input:focus, .contact-form-section textarea:focus{
    border: 1px solid #cc9933;
}

.contact-content-row .site-colum-1 textarea{
    height: 300px;
}

.contact-form-section ::-webkit-input-placeholder{
    color: #cc9933;
}

.contact-form-section ::-ms-input-placeholder{
    color: #cc9933;
}

.contact-form-section ::placeholder{
    color: #cc9933;
}

.contact-form-submit-wraper{
    text-align: center;
    margin: 10px;
}

.contact-form-submit-button{
    background-color: #cae4db;
    border: 1px solid #cc9933;
    width: 248px;
    height: 61px;
    color: #cc9933;
    border-radius: 5px;
}

@media only screen and (min-width: 813px){
    .contact-form-section input, .contact-form-section ::-webkit-input-placeholder, .contact-form-section textarea{
        font-size: 18px;
    }
    .contact-form-section input, .contact-form-section ::-ms-input-placeholder, .contact-form-section textarea{
        font-size: 18px;
    }
    .contact-form-section input, .contact-form-section ::placeholder, .contact-form-section textarea{
        font-size: 18px;
    }

    .contact-content-row .site-colum-1 input, .contact-content-row .site-colum-1 textarea{
        width: 97.5%;
    }

    .contact-form-submit-wraper{
        text-align: right;
    }
}


.blog-content{
    min-height: 70vh;
}
.blog-content h1{
    text-align: center;
}
.KindWords_KindWordsContent__2Qj9k{
    width: 95%;
    margin: 20px auto;
    
}
.KindWords_CommentWrapper__UdLnI{
    margin-bottom: 50px;
}
.KindWords_CommentBy__3O1vp{
    font-family: 'ClickerScript-Regular';
    color: #cc9933;
    margin-left: 20px;
    font-size: 1.5em;
}
.KindWords_GalleryContainer__3wLI6{
    display: -webkit-flex;
    display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width: 888px){
    .KindWords_KindWordsContent__2Qj9k{
        margin-top: 60px;
    }
}


/*https://codepen.io/npellow/pen/yzZQOw*/

.WallGallery_Photos__1pdCt {
    line-height: 0;
    -webkit-column-count: 5;
            column-count: 5;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;  
}
  
.WallGallery_Photos__1pdCt img {
    width: 100% !important;
    height: auto !important;
}

@media (max-width: 1200px) {
    .WallGallery_Photos__1pdCt {
        -webkit-column-count: 4;
                column-count: 4;
    }
}
@media (max-width: 1000px) {
    .WallGallery_Photos__1pdCt {
        -webkit-column-count:  3;
                column-count:  3;
    }
}
@media (max-width: 800px) {
    .WallGallery_Photos__1pdCt {
        -webkit-column-count: 2;
                column-count: 2;
    }
}
@media (max-width: 400px) {
    .WallGallery_Photos__1pdCt {
        -webkit-column-count: 1;
                column-count: 1;
    }
}

  

.site-footer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: 20px solid #7a9d96;
    font-size: 14px;
}
.footer-logo{
    width: 95%;
    margin: auto;
}
.footer-section-left{
    text-align: center;
    width: 100%;
}
.footer-section-middle{
    text-align: center;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.footer-section-right{
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    width: 100%;
    margin-bottom: 30px;
}
.social-media-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}
.social-icon{
    width: 57px;
}

@media only screen and (min-width: 768px){
    .site-footer{
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .footer-section-left, .footer-section-right, .footer-section-middle{
        width: 33%;
    }
    .footer-logo{
        max-width: 200px;
        margin: auto;
    }
    .footer-section-right{
        padding: 0;
    }
    .footer-section-right h2{
        margin: 0;
    }
}
