.site-footer{
    display: flex;
    flex-direction: column;
    border-top: 20px solid #7a9d96;
    font-size: 14px;
}
.footer-logo{
    width: 95%;
    margin: auto;
}
.footer-section-left{
    text-align: center;
    width: 100%;
}
.footer-section-middle{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-section-right{
    text-align: center;
    align-self: center;
    width: 100%;
    margin-bottom: 30px;
}
.social-media-wrapper{
    display: flex;
    justify-content: space-evenly;
}
.social-icon{
    width: 57px;
}

@media only screen and (min-width: 768px){
    .site-footer{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .footer-section-left, .footer-section-right, .footer-section-middle{
        width: 33%;
    }
    .footer-logo{
        max-width: 200px;
        margin: auto;
    }
    .footer-section-right{
        padding: 0;
    }
    .footer-section-right h2{
        margin: 0;
    }
}