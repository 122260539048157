.loader-container{
    background: #ffffff99;
    color: #6e9e96;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0
}
.loader-wrapper{
    background: white;
    width: 150px;
    height: 150px;
    text-align: center;
    border: 1px solid #6e9e96;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.ajax-loader{
    width: 32px;
}
.loader-image{
    margin: 5px 0;
}
.loader-caption{
    margin: 5px 0;
}