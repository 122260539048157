.contact-form-section input, .contact-form-section textarea{
    line-height: normal;
    display: block;
    width: 95%;
    margin: 0 auto;
    font-family: ClickerScript-Regular;
    padding: 5px;
    border: 1px solid #cc9933;
    color: #cc9933;
}

.contact-form-section input:focus, .contact-form-section textarea:focus{
    border: 1px solid #cc9933;
}

.contact-content-row .site-colum-1 textarea{
    height: 300px;
}

.contact-form-section ::placeholder{
    color: #cc9933;
}

.contact-form-submit-wraper{
    text-align: center;
    margin: 10px;
}

.contact-form-submit-button{
    background-color: #cae4db;
    border: 1px solid #cc9933;
    width: 248px;
    height: 61px;
    color: #cc9933;
    border-radius: 5px;
}

@media only screen and (min-width: 813px){
    .contact-form-section input, .contact-form-section ::placeholder, .contact-form-section textarea{
        font-size: 18px;
    }

    .contact-content-row .site-colum-1 input, .contact-content-row .site-colum-1 textarea{
        width: 97.5%;
    }

    .contact-form-submit-wraper{
        text-align: right;
    }
}

