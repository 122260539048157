.portfolio-gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin: 65px auto;
}
.photo-gallery{
    width: 300px;
    margin: 25px;
    padding: 10px 10px 20px 10px;
    border: 1px solid #aaaaaa;
	cursor: pointer;
}
.photo-gallery .caption{
    text-align: center;
    font-family: 'ClickerScript-Regular';
    color: #cc9933;
    font-size: 18px;
}
.photo-gallery img{
    max-width: 300px;
    max-height: 200px;
}

@media only screen and (min-width: 750px){
    .portfolio-gallery .photo-gallery:nth-child(odd){
        -ms-transform: rotate(7deg); /* IE 9 */
        -webkit-transform: rotate(7deg); /* Safari */
        transform: rotate(7deg);
		transition: transform 0.6s;
    }
	
    .portfolio-gallery .photo-gallery:nth-child(even){
        -ms-transform: rotate(-8deg); /* IE 9 */
        -webkit-transform: rotate(-8deg); /* Safari */
        transform: rotate(-8deg);
		transition: transform 0.6s;
    }
	
	.portfolio-gallery .photo-gallery:hover{
		transform: rotate(0deg);
	}

    .photo-gallery{
        box-shadow: 5px 5px 5px #aaaaaa;
        border:none;
    }
}