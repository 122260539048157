
.site-light-box{
    background: black;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    display: flex;
    opacity: 0;
    transition: opacity 0.4s;
}
.show-lightbox{
    opacity: 1;
    z-index: 20;
}
.site-light-box-container{
    background: white;
    width: 80vw;
    margin: auto;
    position: relative;
    max-height: 80vh;
    min-height: 150px;
}
.site-light-box-wrapper{
    max-height: 80vh;
    overflow-y: scroll;
}

.site-light-box-close{
    position: absolute;
    top: -36px;
    right: 0;
    color: #ffffff;
    font-size: 2em;
    cursor: pointer;
}
.site-light-box-prev{
    position: absolute;
    color: #ffffff;
    font-size: 2em;
    left: -30px;
    top: 50%;
    cursor: pointer;
}
.site-light-box-next{
    position: absolute;
    color: #ffffff;
    font-size: 2em;
    right: -30px;
    top: 50%;
    cursor: pointer;
}
.site-light-box-image{
    opacity: 1;
    transition: opacity 0.4s;
}
