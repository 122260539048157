main .content {
    width: 100%;
}
.content{
    font-size: 18px;
}
.price-content .content{
    border-bottom: 20px solid #6e9e96;
}

.price-content-row{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
    justify-content: center;
    overflow: hidden;
}
.price-photo{
    width: 100%;
}
.price-caption{
    text-align: center;
    color: #cc9933;
    font-family: ClickerScript-Regular;
}
.site-colum-1, .site-colum-2{
    width: 95%;
    margin: 2.5px 0;
}
.site-colum-1 figure, .site-colum-2 figure{
    width: 95%;
    margin: auto;
    text-align: center;
}
.price-page-paragrpah{
    width: 95%;
    margin: auto;
    text-align: center;
}

@media only screen and (min-width: 813px){

    .price-content-row{
        margin: 50px 0px;
    }
    .site-colum-1{
        width: 100%;
    }
    .site-colum-2{
        width: 50%; 
    }
    .price-photo{
        max-width: 505px;
        max-height: 309px;
        margin: auto;
    }
    .price-page-paragrpah{
        font-size: 18px;
    }
    .site-colum-2 p{
        width: 90%;
        margin: auto;
        font-size: 28px !important;
        color: #cc9933;
        font-family: ClickerScript-Regular;
    }
    
}

