/*****************************************************
 *                  CLICKER SCRIPT FONTS             *
 ****************************************************/
@font-face {
    font-family: ClickerScript-Regular;
    src: url('../fonts/ClickerScript-Regular.ttf');
}

/*****************************************************
 *                  COOPER HEWITT FONTS              *
 ****************************************************/
@font-face {
    font-family: CooperHewitt-Bold;
    src: url('../fonts/CooperHewitt-Bold.otf');
}
@font-face {
    font-family: CooperHewitt-BoldItalic;
    src: url('../fonts/CooperHewitt-BoldItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Book;
    src: url('../fonts/CooperHewitt-Book.otf');
}
@font-face {
    font-family: CooperHewitt-BookItalic;
    src: url('../fonts/CooperHewitt-BookItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Heavy;
    src: url('../fonts/CooperHewitt-Heavy.otf');
}
@font-face {
    font-family: CooperHewitt-HeavyItalic;
    src: url('../fonts/CooperHewitt-HeavyItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Light;
    src: url('../fonts/CooperHewitt-Light.otf');
}
@font-face {
    font-family: CooperHewitt-LightItalic;
    src: url('../fonts/CooperHewitt-LightItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Medium;
    src: url('../fonts/CooperHewitt-Medium.otf');
}
@font-face {
    font-family: CooperHewitt-MediumItalic;
    src: url('../fonts/CooperHewitt-MediumItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Semibold;
    src: url('../fonts/CooperHewitt-Semibold.otf');
}
@font-face {
    font-family: CooperHewitt-SemiboldItalic;
    src: url('../fonts/CooperHewitt-SemiboldItalic.otf');
}
@font-face {
    font-family: CooperHewitt-Thin;
    src: url('../fonts/CooperHewitt-Thin.otf');
}
@font-face {
    font-family: CooperHewitt-ThinItalic;
    src: url('../fonts/CooperHewitt-ThinItalic.otf');
}