.site-header{
    background: white;
    font-family: 'Clicker Script';
}
.mobile-nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #7a9d96;
}
.mobile-logo{
    max-width: 50px;
}

.bars-wrapper{
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #7a9d96;
    padding: 0 5px 0px 5px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #7a9d96;
    margin: 6px 0;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    z-index: -1;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}
.logo-image{
    display: inline-block;
}
.main-menu a, .main-menu a:visited, .main-menu a:focus{
    color: #cc9933;
    cursor:pointer;
}
.arrow{
    font-family: FontAwesome;
    float: right;

}
.arrow-down::after{
    content: '\f107';
}
.main-menu ul{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.main-menu ul li{
    list-style: none;
    position: relative;
    flex: 1 0 auto;
    text-align: left;
}
.main-menu a{
    text-decoration: none;
    display: block;
    font-size: 2em;
    padding: .8rem 1rem;
    font-family: ClickerScript-Regular;
}

.main-menu ul li ul li{
    width: 100%;
}

#accordion{
    cursor:pointer;
}

@media only screen and (max-width: 888px){
    .mobile-nav-bar{
        position: fixed;
        width: 100%;
        z-index: 9;
        top:0;
        background: white;
    }
    .logo-wrapper{
        display: none;
    }
    .main-menu{
        position: fixed;
        width: 100%;
        background: white;
        height: calc(100vh - 48px);
        top: 48px;
        overflow-y: scroll;
        left: -100%;
        transition: left 0.4s ease-out;
        z-index: 10;
    }
    .main-menu a{
        font-size: 1.2em;
    }
    .main-menu ul li a{
        border-bottom: 1px solid #7a9d96;
    }
    .menu-panel{
        background-color: #cae4db;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    main{
        margin-top: 58px;
        
    }
}

@media only screen and (min-width: 889px){
    .site-header{
        border-bottom: 20px solid #7a9d96;
    }
    .mobile-nav-bar{
        display: none;
    }
    .main-menu ul{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .main-menu ul li{
        align-self: center;
    }
    .main-menu ul li a {
        text-align: center;
    }
	
	/*
    .main-menu ul li a:hover{
        border-bottom: 1px solid #cc9933;
    }
	*/
	
	.main-menu ul li a::after {
        content: '';
		display: block;
		height: 2px;
		background: #cc9933;
		width: 0;
		transition: width 0.6s;
    }
	.main-menu ul li a:hover::after{
		height: 2px;
		width: 100%;
		transition: width 0.6s;
    }
	
    .main-menu ul li.logo-wrapper a:hover{
        border-bottom: none;
    }
	.main-menu ul li.logo-wrapper a:hover::after{
        width: 0;
    }
    .main-menu ul li ul{
        display: none;
        position: absolute;
        width: 200%;
        z-index: 1;
        background: #f3f3f3;
    }
    .main-menu ul li:hover ul{
        display: flex;
        flex-direction: column;
    }
    .main-menu ul li ul li a{
        padding-left: 2rem;
    }

    .logo-wrapper{
        text-align: center;
        margin: 15px auto;
    }

    .logo-image{
        max-width: 250px;
    }
}


