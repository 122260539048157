body{
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-size: 18px;
  font-family: 'CooperHewitt-Book';
  margin: auto;
  
}

.site-component-wrapper{
  opacity: 0;
  transition: opacity 0.4s;
}


h1, h2, h3, h4, h5{
  font-family: 'ClickerScript-Regular';
  color: #cc9933;
}
h1{font-size: 3.55em}
h2{font-size: 2.66em}
h3{font-size: 1.66em}
h4{font-size: 1.3em}

p{
  font-family: 'CooperHewitt-Book';
  color: #003333;
}

main h1{
  text-align: center;
}
main .content{
  width: 95%;
  margin: 65px auto;
}

.content a, .content a:visited, .content a:focus{
  color:#7a9d96;
}
.rotate-20{
  transform: rotate(-20deg);
}

main{
  transition: opacity 0.4s;
}

@media only screen and (min-width: 889px){
  .content p{
    font-size: 18px;
  }
}

