.instagram-section{
    width: 100%;
    text-align: center;
    min-height: 513px;
    background-color: #7a9d96;
    color:#ffffff;
}
.instagram-title{
    font-family: 'ClickerScript-Regular';
    font-size: 2.6em;
}
.instagram-username{
    font-family: 'CooperHewitt-Book';
    font-size: 1.4em;
}
.instagram-gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.instagram-photo-wrapper{
    margin: 2px;
}
.instagram-photo{
    width: 150px;
    height: 150px;
}
@media only screen and (max-width: 400px){
    
}
@media only screen and (min-width: 889px){
    .instagram-title{
        font-size: 3.55em;
    }
    .instagram-username{
        font-size: 1.77em;
    }
}