.carousel-container {
    position: relative;
	width: 100%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
}
.carousel-slider-wrapper {
	display: flex;
	align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
	transition: all 0.5s;
	
}
.carousel-slide {
	flex-shrink: 0;
    height: 100%;
    width: 100%;;
	text-align: center;
}

.carousel-slide img{
    max-width: 1200px;
    margin: 5px auto;
}

.carousel-arrow {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ecf1f1;
    border-radius: 50%;
    cursor: pointer;
    transition: transform ease-in .1s;
}
.carousel-previous-arrow {
    position: absolute;
    top: 50%;
    left: 25px;
    z-index: 5;
	color: #7a9d96;
}

.carousel-next-arrow {
    position: absolute;
    top: 50%;
    right: 25px;
    z-index: 5;
	color: #7a9d96;
}

.fa-arrow-right:before, .fa-arrow-left:before {
    color: #7a9d96;
}