.KindWordsContent{
    width: 95%;
    margin: 20px auto;
    
}
.CommentWrapper{
    margin-bottom: 50px;
}
.CommentBy{
    font-family: 'ClickerScript-Regular';
    color: #cc9933;
    margin-left: 20px;
    font-size: 1.5em;
}
.GalleryContainer{
    display: flex;
	flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 888px){
    .KindWordsContent{
        margin-top: 60px;
    }
}

